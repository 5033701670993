import React, { useState } from 'react'
import { Link } from 'gatsby'
import Pill from './pill'
import styles from '../styles/PublicationList.module.css'
import PlusIcon from '../assets/plus.svg'
import MinusIcon from '../assets/minus.svg'
import NextIcon from '../assets/next.svg'

const PublicationAll = ({ publications, max, readMore }) => {



    return publications?.length > 0? (
        < >

            {
                 publications.filter((o) => !o.data.HideFromNetwork ).slice(0, max).map((publication, i) => (
                    <a key={i} href={publication.data.Source} target='_blank' rel='noreferrer' >
                        <NextIcon/>
                        <div className={styles.title}>
                            <span>{publication.data.Title}</span>
                            {
                              ( publication.data.Journal && (
                                <Pill label={publication.data.Journal} disabled={true}/>
                              ) )  ||  ( publication.data.Source_type && (
                                    <Pill label={publication.data.Source_type} disabled={true}/>
                                ) )
                            }
                            {
                                publication.data.Author && (
                                    <div className={styles.author}>by {publication.data.Author}</div>
                                )
                            }
                        </div>
                    </a>
                ))
            }
            {
                readMore && publications.length > max && (
                    <Link to={readMore} className={styles.readMore}>{`Read more...`}</Link>
                )
            }
        </>
    ) : null
}

export default PublicationAll
